<script setup>
import { LControlLayers, LGeoJson, LMap, LTileLayer } from "@vue-leaflet/vue-leaflet"
import "leaflet/dist/leaflet.css"
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import LFullScreen from "vue-leaflet-fullscreen"
import "vue-leaflet-fullscreen/dist/style.css"
import axios from '@/helpers/axios'
import FilesList from '@/views/maps/FilesList.vue'

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const { t } = useI18n()
const route = useRoute()
const uuid = route.params.uuid
const map = ref(null)
const zoom = ref(17)
const center = ref([0, 0])
const bounds = ref(null)
const accessToken = ref('pk.eyJ1IjoicmVuYXRvbXMiLCJhIjoiY2pzcDNpaDdlMHFvcTQ5cWx3eXJlMDZ2eiJ9.3GsYRV5ahGFqCpb01u7qFw')
const urlOutdoors = ref('https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)
const urlSattelite = ref('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)

const mapRef = ref()

function onMapReady(map) {
  mapRef.value = map
}

const downloadReport = () => {
  axios.get('/maps/' + uuid + '/report').then(response => {
    window.location.href = response.data.url;
  }).catch(e => {
    console.error(e)
  })
}

const updateData = () => {
  axios.get('/maps/' + uuid).then(response => {
    map.value = response.data
    center.value = response.data.bounds.center.reverse()
    bounds.value = [response.data.bounds.lowerLeft.reverse(), response.data.bounds.upperRight.reverse()]
  }).catch(e => {
    console.error(e)
  })
}

updateData()
</script>

<template>
  <div>
    <VCard
      v-if="map !== null"
      :title="map.name"
      :subtitle="map.public === true ? $t('maps.public') : $t('maps.private')"
      class="mb-6"
    >
      <VCardText>
        <VChip
          v-for="layer in map.layers"
          :key="layer.uuid"
          style="margin-right: 4px;"
          variant="elevated"
        >
          {{ layer.name }}
        </VChip>
      </VCardText>
      <VCardText
        v-if="map.notes"
      >
        {{ map.notes }}
      </VCardText>
      <VCardText>
        <VRow>
          <VCol
            cols="12"
            order="2"
            order-sm="1"
          >
            <VRow>
              <VCol
                v-if="map.area"
                cols="12"
                lg="6"
                sm="3"
                class="text-no-wrap"
              >
                <span class="me-2">{{ $t('maps.area') }}</span>

                <VChip
                  label
                  color="primary"
                >
                  <span v-html="map.area + ' m<sup>2</sup>'" />
                </VChip>
              </VCol>
              <VCol
                cols="12"
                lg="6"
                sm="3"
                class="text-no-wrap"
              >
                <span class="me-2">{{ $t('layout.visibility') }}</span>

                <VChip
                  label
                  :color="map.public ? 'warning' : 'primary'"
                >
                  {{ map.public ? $t('maps.public') : $t('maps.private') }}
                </VChip>
              </VCol>
              <VCol
                v-for="data in map.extra_data"
                :key="data.field"
                cols="12"
                lg="6"
                sm="3"
                class="text-no-wrap"
              >
                <span class="me-2">{{ data.field }}</span>

                <VChip
                  label
                  color="primary"
                >
                  {{ data.value }}
                </VChip>
              </VCol>
              <VCol
                v-if="map.attachments && map.attachments.length"
                cols="12"
                class="text-no-wrap"
              >
                <span class="me-2">{{ $t('maps.attachments') }}</span>
                <FilesList
                  :files="map.attachments"
                  :uuid="uuid"
                  :allow-delete="false"
                  @update="updateData"
                />
                  
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
    <VCard
      v-if="map !== null"
      :title="$t('maps.map')"
    >
      <VCardText>
        <div
          style="height: 500px;"
        >
          <LMap
            v-model:zoom="zoom"
            :center="center"
            :max-bounds="bounds"
            :use-global-leaflet="false"
            @ready="onMapReady"
          >
            <LFullScreen :map-ref="mapRef" />
            <LTileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              layer-type="base"
              name="OpenStreetMap"
              :visible="false"
            />
            <LTileLayer
              :url="urlSattelite"
              layer-type="base"
              name="Mapbox Satellite"
              visible
            />
            <LTileLayer
              :url="urlOutdoors"
              layer-type="base"
              name="Mapbox Outdoors"
              :visible="false"
            />
            <LTileLayer
              v-for="(layer, index) in map.layers"
              :key="layer.uuid"
              :url="layer.url"
              layer-type="overlay"
              :options="{tms: 1, opacity: 1, attribution: '', minZoom: 2, maxZoom: 22}"
              :name="layer.name"
              :visible="index === 0"
            />
            <LGeoJson
              v-if="map.geojson"
              :geojson="map.geojson"
              :name="$t('maps.extra')"
              layer-type="overlay"
              :visible="false"
            />
            <LControlLayers
              :collapsed="false"
              position="topright"
            />
          </LMap>
        </div>
      </VCardText>
    </VCard>
  </div>
</template>
