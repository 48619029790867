{
  "layout": {
    "send": "Send",
    "cancel": "Cancel",
    "error": "Error",
    "open": "Open",
    "reset": "Reset",
    "save_changes": "Save changes",
    "visibility": "Visibility",
    "download": "Download",
    "close": "Close",
    "delete": "Delete",
    "edit": "Edit",
    "view_all": "View all",
    "export": "Export",
    "search": "Search",
    "yes": "Yes",
    "no": "No",
    "options": "Options",
    "export_csv": "Exportar CSV",
    "settings": "Settings",
    "save": "Save",
    "shortcuts": "Shortcuts",
    "next": "Next",
    "previous": "Previous",
    "confirm": "Confirm",
    "active": "Active",
    "view": "View",
    "load_more": "Load more"
  },
  "auth": {
    "logout": "Logout",
    "login": "Login",
    "register": "Register",
    "no_account": "Don't have an account yet?",
    "roles": {
      "admin": "Administrator",
      "tester": "Tester",
      "user": "User",
      "marketing": "Marketing"
    },
    "welcome": "Welcome to {name}",
    "login_description": "Please sign-in to access your content",
    "email": "Email",
    "password": "Password",
    "forgot_password_login": "Forgot Password?",
    "lets_start": "Let's start",
    "already_have_account": "Already have an account?",
    "agree": "I agree to",
    "privacy": "privacy policy",
    "register_description": "Get the latest field insights and predictions!",
    "verify_email": "Verify your email",
    "verify_email_description": "Account activation link sent to your email address: {email} Please follow the link inside to continue.",
    "resend": "Resend",
    "email_resent": "Email resent! Please check your inbox for the activation link. If you still don't see the email, please check your spam folder.",
    "reset_link": "Send reset link",
    "forgot_password": "Forgot Password?",
    "forgot_password_description": "Enter your email and we'll send you instructions to reset your password",
    "reset_link_sent": "Reset link sent",
    "reset_password": "Reset Password",
    "new_password": "New Password",
    "confirm_password": "Confirm Password",
    "reset": "Reset",
    "pasword_reset": "Password reset",
    "name": "Name",
    "organization": "Organization",
    "wrong": "Wrong email or password"

  },
  "navigation": {
    "dashboard": "Dashboard",
    "devices": {
      "title": "Devices",
      "mine": "Mine",
      "public": "Public"
    },
    "assistant": "Assistant",
    "groups": "Groups",
    "maps": "Maps",
    "notebooks": "Field notebooks",
    "admin": "Admin",
    "logs": "Logs",
    "users": "Users",
    "blog": "Blog",
    "debug": "Debug",
    "contacts": "Contacts",
    "webcams": "Webcams",
    "analyses": "Analyses",
    "satellite": "Satellite",
    "api_keys": "API Keys",
    "observations": "Observations"
  },
  "dashboard": {
    "statistics": {
      "title": "Statistics",
      "devices": "Devices",
      "groups": "Groups",
      "parcels": "Parcels",
      "maps": "Maps",
      "notebooks": "Field notebooks"
    },
    "devices": {
      "title": "Devices",
      "no_devices": "No devices"
    },
    "maps": {
      "title": "Maps",
      "no_maps": "No maps"
    },
    "notebooks": {
      "title": "Field notebooks",
      "notes": "Notes: {number}",
      "no_notebooks": "No field notebook"
    },
    "hello": "Hello {name}",
    "credits": "{number} credits",
    "get_credits": "Get credits",
    "favorites": {
      "title": "Favorites",
      "no_favorites": "No favorites"
    }
  },
  "tables": {
    "showing": "Showing {firstIndex} to {lastIndex} of {totalItems} entries",
    "show": "Show:"
  },
  "devices": {
    "title": "Devices",
    "search_device": "Search device",
    "name": "Name",
    "model": "Model",
    "location": "Location",
    "last_data": "Last Data",
    "no_devices": "No devices found",
    "my_devices": "My devices",
    "public_devices": "Public devices",
    "public": "Public",
    "private": "Private",
    "no_data": "No data",
    "view_all": "View all",
    "map": "Mapa",
    "elevation": "Elevation: {number}",
    "photos": "Photos",
    "no_photos": "No photos",
    "no_location": "No location",
    "photo": "Photo",
    "add_photo": "Add photo",
    "photo_added": "Photo added",
    "edit_location": "Edit location",
    "location_updated": "Location updated",
    "wind": "Wind",
    "humidity": "Humidity",
    "uv": "UV Index",
    "battery": "Battery",
    "gust": "Gust: {number}km/h",
    "directions": {
      "n": "North",
      "ne": "Northeast",
      "e": "East",
      "se": "Southeast",
      "s": "South",
      "sw": "Southwest",
      "w": "Southwest",
      "nw": "Northwest"
    },
    "all_channels": "All channels",
    "level": "Level",
    "water_temperature": "Water temperature",
    "ph": "pH",
    "redox": "Redox",
    "oxygen_saturation": "Oxygen saturation",
    "oxygen_mgl": "Oxygen mg/L",
    "oxygen_ppm": "Oxygen PPM",
    "conductivity": "Conductivity",
    "salinity": "Salinity",
    "tds_kci": "TDS-KCI",
    "csv_exported": "CSV file exported",
    "edit_device": "Edit device",
    "public_device": "Public device",
    "hibernated_device": "Hibernated device",
    "settings_updated": "Settings updated",
    "notes": "Notes",
    "views": "Views",
    "maintenance": "Maintenance",
    "view_warnings": "View warnings",
    "warnings": "Warnings",
    "warnings_types": {
      "disease": "Disease",
      "technical": "Technical"
    },
    "warnings_descriptions": {
      "mildew": "Mildew",
      "no_data": "No data"
    },
    "type": "Type",
    "description": "Description",
    "date": "Date",
    "no_warnings": "No warnings",
    "hourly_average": "Hourly average",
    "air_quaility": "Air Quality",
    "air_quality_levels": {
      "0": "Very Good",
      "1": "Good",
      "2": "Average",
      "3": "Weak",
      "4": "Bad"
    },
    "water_quaility": "Water Quality",
    "water_quality_levels": {
      "0": "Good or above",
      "1": "Less than good"
    },
    "view_weather_warnings": "View weather alerts"
  },
  "assistant": {
    "title": "Assistant",
    "description": "The assistant is an automated tool that uses the power of artificial intelligence to provide advice and information from experts on vineyard management. The assistant is designed to assist vineyard owners, winemakers, and anyone interested in vineyard management. It can answer questions on a wide range of topics, such as soil management, pest and disease control, harvest time, pruning, irrigation, organic farming, soil quality, drainage, mechanization, vineyard budgeting, precision agriculture, environmental impact reduction, vineyard labor, and sustainability, among others.",
    "send": "Send"
  },
  "debug": {
    "title": "Debug",
    "email": "Send debug email",
    "email_sent": "Debug email sent"
  },
  "groups": {
    "title": "Groups",
    "description": "Groups allow you to share your devices with other users and see devices shared with you.",
    "create_group": "Create group",
    "members_number": "{number} members",
    "member_number": "{number} member",
    "admin": "Administrator",
    "member": "Member",
    "devices_number": "Devices: {number}",
    "group_created": "Group created",
    "description_field": "Description",
    "name_field": "Name",
    "devices": "Devices",
    "add_devices": "Add devices",
    "device_deleted": "Device deleted",
    "devices_added": "Devices added: {number}",
    "users": "Users",
    "user": {
      "user": "User",
      "email": "Email",
      "add_user": "Add user",
      "role": "Role",
      "no_users": "No users",
      "search_user": "Search user",
      "type": {
        "admin": "Administrator",
        "guest": "Guest"
      },
      "user_added": "User added",
      "user_deleted": "User deleted",
      "email_not_found": "Email not found",
      "already_in_group": "User already in group"
    },
    "no_groups": "No groups",
    "name": "Name",
    "date": "Date",
    "search_group": "Search group"
  },
  "settings": {
    "title": "Settings",
    "account": "Profile",
    "upload_new_photo": "Upload new photo",
    "photo_details": "Allowed JPG or PNG. Max size of 5M",
    "name": "Name",
    "email": "E-mail",
    "organization": "Organization",
    "phone": "Phone number",
    "security": "Security",
    "photo_updated": "Photo updated",
    "account_updated": "Profile updated",
    "change_password": "Change Password",
    "current_password": "Current Password",
    "new_password": "New Password",
    "confirm_new_password": "Confirm New Password",
    "password_updated": "Password updated"
  },
  "maps": {
    "title": "Maps",
    "search_map": "Search map",   
    "name": "Name",
    "area": "Area",
    "layers": "Layers",
    "sampled_at": "Sampling date",
    "status": "Status",
    "no_maps": "No maps",
    "pending": "Pending",
    "processed": "Processed",
    "public": "Public",
    "private": "Private",
    "report": "Report",
    "download_files": "Download files",
    "map": "Map",
    "extra": "Extra",
    "add_map": "Create map",
    "user": "User",
    "notes": "Notes",
    "extra_fields": "Extra fields",
    "add_extra_field": "Add extra field",
    "map_created": "Mapa created",
    "add_layer": "Add layer",
    "layer_created": "Layer created. Processing might take a few minutes.",
    "edit_map": "Edit map",
    "add_report": "Add report",
    "add_files": "Add attachment",
    "no_layers": "No layers",
    "visible": "Visible",
    "date": "Date",
    "layer_updated": "Layer updated",
    "layer_deleted": "Layer deleted",
    "add_geojson": "Add geojson",
    "geojson": "Geojson",
    "layer_name": "Layer name",
    "tiff": "TIFF file",
    "geojson_added": "Geojson added",
    "geojson_deleted": "Geojson deleted",
    "delete_geojson": "Delete geojson",
    "delete_geojson_confirm": "Are you sure you want to delete geojson?",
    "report_added": "Report added",
    "pdf": "PDF",
    "delete_report": "Delete report",
    "delete_report_confirm": "Are you sure you want to delete the report?",
    "report_deleted": "Report deleted",
    "file_deleted": "Attachment deleted",
    "file": "Attachment",
    "delete_file": "Delete attachment",
    "delete_file_confirm": "Are you sure you want to delete the attachment?",
    "file_added": "Attachment added",
    "logs": "Logs",
    "attachments": "Attachments",
    "add_attachment": "Add attachment",
    "value": "Value"
  },
  "landing": {
    "go_dashboard": "Go to my dashboard",
    "heading": "One dashboard to manage all your crops",
    "subheading": "Using drones and sensors in farms and forests for vegetation monitoring",
    "view_devices": "View devices",
    "revolution": "Revolutionize Your Agriculture with Kropie",
    "explore": "Explotre our free features.",
    "contact": "Contact",
    "contact_us": "Contact us",
    "contact_us_sub": "Any question or remark? just write us a message",
    "need_help": "Need help?",
    "faq": "FAQ",
    "about": "About",
    "home": "Home",
    "login": "Login",
    "register": "Register",
    "dashboard": "Dashboard",
    "stations": "Online Stations",
    "stations_description": "View public weather stations data",
    "own_station": "Want your own weather station?",
    "own_station_description": "Contact us to know more.",
    "email": "Email",
    "address": "Address",
    "send_message": "Send a message",
    "name": "Name",
    "write_message": "Write a message",
    "message": "Message",
    "send": "Send",
    "devices": "Devices",
    "sensors": "Sensors",
    "notebooks": "Field notebooks",
    "maps": "Maps",
    "contact_success": "Message successfully sent",
    "webcams": "Weather Webcams",
    "webcams_description": "Watch weather webcams close to you",
    "open_webcam": "Open Webcam",
    "features": {
      "title": "Features",
      "subtitle": "All you need to manage your farm",
      "weather_stations": "Weather Stations",
      "weather_stations_description": "Get accurate and up-to-date weather data with our high-quality weather stations. Our stations collect real-time data on various weather parameters, such as temperature, humidity, rainfall, and wind speed, allowing you to make informed decisions about your agricultural practices. With our weather stations, you can stay ahead of weather-related risks and optimize your crop production.",
      "weather_stations_p1": "Real-time data",
      "weather_stations_p2": "Easy to install and use",
      "weather_stations_p3": "Compatible with our platform",
      "weather_stations_p4": "Accurate data",
      "ai_assistant": "AI Assistant",
      "ai_assistant_description": "Get intelligent and personalized assistance with our AI-powered assistant. Our assistant is trained on vast amounts of agricultural data and can help you with a variety of tasks, from setting up irrigation schedules to identifying potential pests or diseases, and even providing insights on crop growth. Using natural language processing, our assistant can understand user queries and provide relevant information or recommendations, helping you make informed decisions and optimize your crop production.",
      "ai_assistant_p1": "Relevant information and recommendations",
      "ai_assistant_p2": "Assist with simple tasks",
      "ai_assistant_p3": "Help with decison-making",
      "notebook": "Field Notebook",
      "notebook_description": "Record and keep track of important information about your crops and farming activities with our digital field notebook. Easily log planting dates, crop growth stages, and any issues you encounter in the field. You can also share this information with other members of your team for better collaboration and decision-making.",
      "notebook_p1": "Easy and organized record-keeping",
      "notebook_p2": "Track planting dates and other activities",
      "notebook_p3": "Share with team members",
      "satellite": "Satellite Imagery",
      "satellite_description": "Stay up-to-date with the latest satellite imagery of your farm or field. Our platform provides you with high-resolution satellite imagery updated twice a week. This allows you to monitor changes in your crops and the surrounding environment, helping you make informed decisions about crop management. Our imagery also provides valuable insights into weather patterns, soil moisture levels, and potential issues such as pest outbreaks or disease spread, enabling you to take proactive measures to protect your crops.",
      "satellite_p1": "Updated twice a week",
      "satellite_p2": "Multiple agricultural indexes",
      "satellite_p3": "Helps identify potential issues with crops",
      "drones": "Drone Maps",
      "drones_description": "Gain valuable insights into the health of your crops with our drone maps. Our platform provides you with detailed maps of your fields, including various vegetation indices such as NDVI (Normalized Difference Vegetation Index), NDRE (Normalized Difference Red Edge), and SAVI (Soil Adjusted Vegetation Index).",
      "drones_p1": "High-resolution imagery of your field",
      "drones_p2": "NDVI, NDRE, and others",
      "drones_p3": "Detailed analysis of field",
      "drones_p4": "Access to files and report online",
      "monitoring": "Monitoring",
      "monitoring_description": "Get real-time monitoring and risk assessment for your crops with our platform's advanced algorithms. We calculate key risks such as mildew and frost, as well as important indexes like the Winkler and Huglin indexes and cold hours, giving you valuable insights to help you make informed decisions for your farm or field.",
      "monitoring_p1": "Risks calculation for mildew and frost",
      "monitoring_p2": "Indexes: Huglin, Winkler and Cold-hours",
      "monitoring_p3": "Alerts"
    },
    "analyses_heading": "Soil, Water, and Leaf Analyses",
    "analyses_subheading": "Your Kropie account now allows you to request analyses and consult the results at any time.",
    "request_analyses": "Request Analyses",
    "analyses_types": {
      "heading": "Analyses Types",
      "water": {
        "title": "Water",
        "parameters": "Bicarbonates, boron, calcium, chlorides, magnesium, nitrates, electrical conductivity, pH, adjusted sodium adsorption ratio, and sodium"
      },
      "soil": {
        "title": "Soil",
        "parameters": "pH (H2O), Organic Matter, Texture, Lime Requirement, Phosphorus, Potassium, Magnesium"
      },
      "leaf": {
        "title": "Leaf",
        "parameters": "Nitrogen, phosphorus, potassium, calcium, magnesium, sulfur, iron, manganese, zinc, copper, and boron"
      }
    },
    "analyses": "Analyses",
    "analyses_features": {
      "chip": "Advantages",
      "subtitle": "Benefit from the conveniences of the Kropie portal",
      "features": {
        "1": {
          "heading": "Access Anytime, Anywhere",
          "description": "A single point of control and management, allowing you to more easily compare results over time."
        },
        "2": {
          "heading": "Choose the Best Service Provider",
          "description": "Select the best proposal for your request. If you already work with a service provider, you can also request that they register on the platform and choose to continue working with them."
        },
        "3": {
          "heading": "Set Alerts for Your Requests",
          "description": "Whether to meet legal obligations or ensure proper monitoring, you can schedule your requests at intervals defined by you. You'll receive a notification to alert you."
        }
      }
    },
    "analyses_faq": {
      "q1": "What is the new Soil, Water, and Leaf Analysis feature?",
      "a1": "This feature connects users with professional service providers for comprehensive analysis of soil, water, and leaves. It aims to assist farmers, gardeners, and agriculture professionals in obtaining accurate assessments of their lands and crop health, facilitating informed decision-making for better crop management and environmental sustainability.",
      "q2": "How does it work?",
      "a2": "Submission of Request: Users submit a request for soil, water, or leaf analysis through our platform. Matching: Based on the user's specific needs and location, our system matches them with a qualified service provider specializing in agricultural analysis. Analysis Process: The service provider conducts the analysis and provides a detailed report with findings and recommendations.",
      "q3": "What information do I need to provide?",
      "a3": "To ensure accurate matching, users should provide: • The type of analysis needed (soil, water, or leaf) • Specific concerns or objectives (e.g., nutrient deficiencies, pest resistance) • Location details for service area matching • Preferred time for analysis",
      "q4": "What are the benefits of this feature?",
      "a4": "By using this feature, you can: • Access professional and specialized analysis services tailored to your needs • Receive expert recommendations to improve soil quality, water usage, and plant health • Enhance crop yield and quality through informed decisions • Save time and effort in searching for reputable service providers",
      "q5": "How long does it take to get a match and receive a report?",
      "a5": "The matching process is usually swift, often completed within 24 hours. The time to receive your analysis report will vary depending on the service provider's schedule and the complexity of the analysis. Typically, reports are provided within a few days to a week after the service provider completes the analysis, but this timeline is always at the discretion of the service provider.",
      "q6": "How often should I request an analysis?",
      "a6": "We recommend requesting an analysis at least once per growing season or whenever you observe significant changes in crop health, soil condition, or water quality. Regular analyses can help you monitor the health of your land over time and make necessary adjustments to your agricultural practices."
      }
  },
  "faq": {
    "title": "FAQ",
    "title_long": "Frequently Asked Questions",
    "description": "Let us help answer the most common questions.",
    "q1": "Can I access the data collected by the weather station in real-time?",
    "a1": "Yes, you can access the data collected by the weather station in real-time through our platform. We provide real-time updates of the weather parameters measured by the station.",
    "q2": "How does your platform calculate important parameters for culture growth?",
    "a2": "Our platform uses various data inputs such as weather data, soil data, and crop data to calculate important parameters for culture growth such as evapotranspiration, irrigation requirements, and crop water stress. We use advanced algorithms and machine learning techniques to provide accurate calculations.",
    "q3": "What kind of drone maps and indexes can I expect to see on Kropie?",
    "a3": "Our platform provides drone maps with various indexes such as NDVI (Normalized Difference Vegetation Index), NDRE (Normalized Difference Red Edge), and SAVI (Soil Adjusted Vegetation Index). These indexes can help you assess plant health and identify potential issues with your crops.",
    "q4": "How does the AI Assistant work?",
    "a4": "Our AI assistant uses natural language processing to understand user queries and provide relevant information or recommendations. It can help you with tasks such as setting up irrigation schedules, identifying potential pests or diseases, and helping you with simple tasks.",
    "q5": "What is a field notebook and how does it help me with my agricultural work?",
    "a5": "Our field notebook is a digital notebook that allows you to record important information about your crops and farming activities. It can help you keep track of planting dates, crop growth stages, and any issues you encounter in the field. You can also share this information with other members of your team.",
    "q6": "How often is the satellite imagery updated on Kropie?",
    "a6": "We update our satellite imagery twice a week, providing you with the latest information about your farm or field.",
    "q7": "Is my data secure on your platform?",
    "a7": "Yes, we take data security very seriously and use industry-standard encryption and security measures to protect your data. We also have strict data privacy policies in place to ensure that your data is only used for the intended purposes."
  },
  "notebooks": {
    "title": "Field notebooks",
    "description": "Welcome to your Notebook page! This is where you can view and manage all your notebooks related to agriculture. Whether you're a farmer, a gardener, or simply have a green thumb, this page is designed to help you keep track of your ideas, observations, and plans for your crops.",
    "create": "Create field notebook",
    "notes_number": "Notes: {number}",
    "notebook_created": "Field notebook created",
    "add_notebook": "Create field notebook",
    "edit_note": "Edit notebook",
    "name": "Name",
    "add_note": "Add note",
    "add": "Add",
    "note_created": "Note created",
    "note_updated": "Note updated",
    "description_field": "Description",
    "note_type": "Note type",
    "types": {
      "observation": "Observation",
      "weather": "Weather",
      "disease": "Disease",
      "pruning": "Pruning",
      "fertilization": "Fertilization",
      "pesticide": "Pesticide",
      "harvest": "Harvest",
      "labor": "Labor",
      "expense": "Expense",
      "sale": "Sale",
      "other": "Other"
    },
    "notes": "Notes",
    "search_note": "Search note",
    "select_type": "Select a note type",
    "date": "Date",
    "no_notes": "No notes",
    "location": "Location",
    "last_note": "Last note: {date}",
    "delete_notebook": "Delete field notebook",
    "deleted_notebook": "Field notebook deleted",
    "delete_notebook_confirm": "Are you sure you want to delete this field notebook?",
    "delete_note": "Delete note",
    "deleted_note": "Note deleted",
    "delete_note_confirm": "Are you sure you want to delete this note?",
    "exporting_notebook": "Exporting field notebook",
    "last_entry": "Last note",
    "user": "User",
    "entries": "Entries",
    "no_notebooks": "No notebooks"
  },
  "days": {
    "short": {
      "0": "Sun",
      "1": "Mon",
      "2": "Tue",
      "3": "Wed",
      "4": "Thu",
      "5": "Fri",
      "6": "Sat"
    }
  },
  "logs": {
    "title": "Logs",
    "module": "Module",
    "user": "User",
    "description": "Description",
    "date": "Date",
    "no_logs": "No logs",
    "search_log": "Search log"
  },
  "users": {
    "title": "Users",
    "search_users": "Search users",
    "name": "Name",
    "email": "Email",
    "organization": "Organization",
    "phone": "Phone",
    "credits": "Credits",
    "type": "Type",
    "no_users": "No users",
    "roles": {
      "admin": "Admin",
      "tester": "Tester",
      "user": "User",
      "marketing": "Marketing"
    },
    "details": "Details",
    "add_credits": "Add credits",
    "credits_added": "Credits added",
    "last_seen": "Online",
    "change_avatar": "Change avatar",
    "photo": "Photo",
    "avatar_updated": "Avatar updated"
  },
  "blog": {
    "blog": "Blog",
    "search_post": "Search post",
    "title": "Title",
    "date": "Date",
    "no_posts": "No posts",
    "slug": "Slug",
    "post_added": "Post added",
    "add_post": "Add post",
    "articles": "Articles"
  },
  "contacts": {
    "title": "Contacts",
    "search_contact": "Search contact",
    "no_contacts": "No contacts",
    "name": "Name",
    "email": "Email",
    "message": "Message",
    "answered": "Answered",
    "date": "Date"
  },
  "forbidden": {
    "title": "You are not authorized!",
    "subtitle": "You dont have permission to access this page. Go Home!",
    "button": "Back to Home"
  },
  "not_found": {
    "title": "Page Not Found",
    "subtitle": "We couldn't find the page you are looking for.",
    "button": "Voltar ao início"
  },
  "webcams": {
    "search_webcam": "Search webcam",
    "name": "Name",
    "location": "Location",
    "views": "Views",
    "updated": "Updated",
    "no_webcams": "No webcams"
  },
  "analyses": {
    "title": "Analyses",
    "companies": "Analyses providers",
    "no_companies": "No providers",
    "search_company": "Search provider",
    "provider_name": "Provider",
    "provider_users": "Users",
    "provider_analyses": "Analyses",
    "users": "Users",
    "user": "User",
    "email": "Email",
    "add_user": "Add user",
    "no_users": "No users",
    "search_user": "Search user",
    "user_added": "User added",
    "user_deleted": "User deleted",
    "email_not_found": "Email not found",
    "already_added": "User already added",
    "requests": "Requests",
    "search_request": "Search request",
    "accepted": "Accepted",
    "collect": "Collect",
    "paid": "Paid",
    "type": "Type",
    "date": "Date",
    "water": "Water",
    "soil": "Soil",
    "leaf": "Leaf",
    "no_requests": "No requests",
    "request_analysis": "Request analysis",
    "analysis_type": "Analysis type",
    "select_analysis_type": "Select analysis type",
    "location": "Location",
    "select_location": "Select location",
    "info": "Aditional information",
    "add_info": "Add additional information",
    "review": "Review",
    "review_request": "Review request",
    "water_description": "Evaluates water quality for contaminants, pH, and mineral content.",
    "soil_description": "Assesses soil health, nutrients, and potential pollutants.",
    "leaf_description": "Examines plant leaf nutrients for optimal growth.",
    "request_collect": "Request sample to be collected?",
    "collect_description": "If you do not request your sample to be collected you will receive instructions to post it through mail.",
    "observations": "Observations",
    "observations_description": "Please add any information you might consider relevant.",
    "review_note": "Once your request is approved you will receive an email. You can contact us regarding your request in the request page.",
    "number": "Number",
    "request_submitted": "Analyses request successfully submitted",
    "request": "Request",
    "finished": "Finished",
    "unpaid": "Unpaid",
    "collect_requested": "Collect requested",
    "request_details": "Request details",
    "total": "Total",
    "comments": "Comments",
    "send": "Send",
    "comment_placeholder": "Type your comment...",
    "comment_sent": "Comment sent",
    "activity": "Activity",
    "request_placed": "Request was placed (#{number})",
    "request_placed_description": "Your request has been placed successfully",
    "result_published": "Result published",
    "result_published_description": "The result of the analyses was published and is now ready",
    "invoice_uploaded": "New invoice added",
    "invoice_uploaded_description": "There is a new invoice available",
    "receipt_uploaded": "New receipt added",
    "receipt_uploaded_description": "There is a new receipt available",
    "request_finished": "Request finished",
    "request_finished_description": "This request has been set has finished",
    "download_receipt": "Download Receipt",
    "download_invoice": "Download Invoice",
    "download_result": "Download Result",
    "request_accepted": "Request accepted",
    "request_accepted_description": "Your request has been accepted",
    "request_refused": "Request refused",
    "request_refused_description": "Your request has been refused",
    "payment_done": "Payment done",
    "payment_done_description": "Your request has been marked has paid",
    "status": "Status",
    "pending": "Pending",
    "progress": "In progress",
    "upload_invoice": "Upload invoice",
    "invoice_added": "Invoice added",
    "pdf": "PDF",
    "than_last_month": "than last month",
    "pending_alert": "This request is stil pending. Please accept or refused it.",
    "accept": "Accept",
    "refuse": "Refuse",
    "refuse_request": "Refuse request",
    "refuse_request_confirm": "Are you sure you want to refuse this request? If so, please also write a comment in the request specifying the reason.",
    "refused_alert": "This request was refused at {date}.",
    "refused": "Refused",
    "result": "Result",
    "result_available": "Result Available",
    "result_available_description": "The result for this request is available. You can view and download it below.",
    "pending_alert_user": "This request is stil pending. Please until it is accepted or refused.",
    "set_price": "Set price",
    "set_price_update_warning": "The user will be notified when the price is updated.",
    "price": "Price",
    "price_set_success": "Price successfully updated",
    "replace_invoice": "Replace invoice",
    "upload_receipt": "Upload receipt",
    "replace_receipt": "Replace receipt",
    "upload_result": "Upload result",
    "replace_result": "Replace result",
    "receipt_added": "Receipt added",
    "result_added": "Result added",
    "clients": "Clients",
    "search_client": "Search client",
    "no_clients": "No clients",
    "finish_request": "Finish request",
    "finish_request_confirm": "Are you sure you want to finish this request? Once finisish it cannot me changed.",
    "finished_alert": "This request is finished.",
    "details": "Details",
    "spent": "Spent",
    "analyses": "Analyses",
    "new_request": "New request",
    "getting_location": "Getting location...",
    "no_address": "No address found",
    "search_address": "Search address",
    "address": "Address",
    "provider": "Provider",
    "set_as_paid": "Set as paid",
    "set_as_paid_confirm": "Do you want to set this request as paid?",
    "request_set_as_paid": "Request set as paid",
    "request_new_analysis": "Request analysis",
    "request_new_analysis_description": "Click the button below to start a new analysis request. Please fill the form accordingly.",
    "start": "Start",
    "invoiced": "Invoiced",
    "open_google_maps": "Open Google Maps",
    "add_provider": "Add provider",
    "provider_created": "Provider created",
    "requests_not_attributed": "Requests not attributed",
    "to_be_attributed": "To be attributed",
    "select_provider": "Select provider",
    "provider_attributed": "Provider attributed"
  },
  "satellite": {
    "title": "Satellite Imagery",
    "parcels": "Parcels",
    "search_parcel": "Search parcel",
    "name": "Name",
    "area": "Area",
    "user": "User",
    "validated": "Validated",
    "last_data": "Last Data",
    "active": "Active",
    "no_parcels": "No parcels",
    "no_data": "No data",
    "add_parcel": "Add parcel",
    "geojson": "Geojson",
    "number_days": "Number of historical days",
    "index": "Index",
    "select_index": "Select index",
    "no_entries": "There is yet no satellite imagery for the selected index.",
    "min": "Minimum",
    "max": "Maximum",
    "average": "Mean",
    "parcel_created_success": "Parcel successfully created",
    "description": "Explore satellite imagery and indexes like NDVI and NDWI.",
    "indexes": {
      "ndvi": "NDVI",
      "ndvi_long": "Normalized Difference Vegetation Index",
      "ndvi_description": "NDVI is used to quantify the density of vegetation (live green biomass). NDVI can vary between -1 and 1. NDVI increases in proportion to vegetation growth. An area with dense, healthy vegetation will have a high NDVI value (> 6). High NDVI values (between 0.6 and 1) indicate healthy vegetation, while low NDVI values (between -0.1 and 0.1) indicate barren land, water, or clouds. Values between 0.1 and 0.6 indicate moderate vegetation cover.",
      "evi": "EVI",
      "evi_long": "Enhanced Vegetation Index",
      "evi_description": "EVI is a vegetation index designed to enhance the vegetation signal with improved sensitivity in high biomass regions with dense vegetation. The value range for EVI is –1 to +1, and for healthy vegetation, it varies between 0.2 and 0.8. EVI is responsive to canopy structural variations, including leaf area index (LAI), canopy type, plant physiognomy, and canopy architecture.",
      "evi2": "EVI2",
      "evi2_long": "Enhanced Vegetation Index 2",
      "evi2_description": "EVI2 is an extension of EVI that, it is used reduce the impact of potential noise in EVI.",
      "ndwi": "NDWI",
      "ndwi_long": "Normalized Difference Water Index",
      "ndwi_description": "NDWI can be used to monitor changes in water content of leaves. NDWI values can range from -1 to 1. High NDWI values correspond to high plant water content and coating of high plant fraction, whereas low NDWI values correspond to low vegetation content and cover with low vegetation.",
      "nri": "NRI",
      "nri_long": "Nitrogen Reflectance Index",
      "nri_description": "NRI is an index used to estimate the nitrogen (N) content in leaves. Higher values can mean higher levels of nitrogen while lower values mean the opposite.",
      "dswi": "DSWI",
      "dswi_long": "Disease-Water Stress Index",
      "dswi_description": "DSWI can be used to detect water-stressed crops at a canopy level being also sensitive to changes in leaf pigments, internal leaf structure and moisture content.",
      "truecolor": "TC",
      "truecolor_long": "True Color",
      "truecolor_description": "True color are images representing the light visible to the human eye. Can be used to visually assess the state of crops or fields.",
      "falsecolor": "FC",
      "falsecolor_long": "False Color",
      "falsecolor_description": "False color are images created by using wavelengths outside the visible spectrum to highlight specific features of interest. This can enhance the contrast between different types of vegetation or between vegetation and non-vegetated areas, making it easier to identify patterns or anomalies."
    },
    "disclaimer": "Despite the indices provided having undergone extensive scientific validation, the results they offer should be seen as aids to decision-making, with their outcomes needing to be verified through on-site inspections. Furthermore, since the indices are calculated based on satellite-acquired images, fluctuations in values or data gaps may occur due to unfavorable weather conditions at the time of data acquisition, such as the presence of clouds or shadows caused by them.",
    "more_info": "More information",
    "soil_data": "Soil Info",
    "soil_moisture": "Soil Moisture",
    "view_historic": "View historical",
    "surface_temperature": "Surface temperature",
    "surface_temperature_10cm": "Temperature at 10cm"
  },
  "api_keys": {
    "title": "API Keys",
    "add_key": "Add key",
    "device": "Device",
    "key": "Key",
    "read": "Read",
    "write": "Write",
    "updated": "Updated",
    "requests": "Requests",
    "no_keys": "No keys",
    "search_key": "Search key"
  },
  "observations": {
    "title": "Observations",
    "search_observation": "Search observation",
    "observation_title": "Title",
    "user": "User",
    "date": "Date",
    "no_observations": "No observations",
    "type": "Type",
    "types": {
      "wind": "Wind",
      "hail": "Hail",
      "clouds": "Clouds",
      "flood": "Flood",
      "downpour": "Downpour",
      "rain": "Rain",
      "snow": "Snow",
      "fog": "Fog",
      "thunderstorm": "Thunderstorm",
      "ice": "Ice",
      "other": "Other"
    }
  }
}
