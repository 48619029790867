<script setup>
import { LControlLayers, LGeoJson, LMap, LTileLayer, LImageOverlay } from "@vue-leaflet/vue-leaflet"
import "leaflet/dist/leaflet.css"
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import LFullScreen from "vue-leaflet-fullscreen"
import "vue-leaflet-fullscreen/dist/style.css"
import axios from '@/helpers/axios'
import Loader from '@/views/Loader.vue'
import moment from "moment";
import ChartJsLineChart from '@/views/satellite/ChartJsLineChart.vue'
import Breadcrumbs from '@/views/general/Breadcrumbs.vue'
import SoilCard from '@/views/satellite/SoilCard.vue'

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const { t } = useI18n()
const route = useRoute()
const uuid = route.params.uuid
const map = ref(null)
const zoom = ref(17)

const isInfoDialogVisible = ref(false)

const rowPerPage = ref(5)
const currentPage = ref(1)
const totalPage = ref(1)
const key = ref('ndvi')
const keys = ref([])
const totalItems = ref(0)
const entries = ref([])

const selectedEntry = ref(null)

const accessToken = ref('pk.eyJ1IjoicmVuYXRvbXMiLCJhIjoiY2pzcDNpaDdlMHFvcTQ5cWx3eXJlMDZ2eiJ9.3GsYRV5ahGFqCpb01u7qFw')
const urlOutdoors = ref('https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)
const urlSattelite = ref('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)

const mapRef = ref()

const bread = ref([
  {
    text: t('satellite.title'),
    link: { name: 'satellite' }
  },
  {
    text: '...'
  }
])

function onMapReady(m) {
  mapRef.value = m
  m.fitBounds(map.value.bounds)
}


const getIndexes = () => {
  axios.get('/satellite/indexes').then(response => {
    const items = []

    response.data.data.forEach(element => {
      items.push({
        key: element.key,
        name: t('satellite.indexes.' + element.key) + ' - ' + t('satellite.indexes.' + element.key + '_long')
      })
    });
    items.reverse()
    keys.value = items
  }).catch(e => {
    console.error(e)
  })
}

getIndexes()

const getData = () => {
  axios.get('/satellite/' + uuid).then(response => {
    map.value = response.data
  }).catch(e => {
    console.error(e)
  })
}

getData()

const getEntries = (page) => {
  axios.get('/satellite/' + uuid + '/entries', {
    params: {
      perPage: rowPerPage.value,
      currentPage: currentPage.value,
      key: key.value,
    },
  }).then(response => {
    entries.value = entries.value.concat(response.data.data)
    totalPage.value = response.data.meta.last_page
    totalItems.value = response.data.meta.total
    if (entries.value.length > 0) {
      selectedEntry.value = entries.value[0]
    } else {
      selectedEntry.value = null
    }
  }).catch(e => {
    console.error(e)
  })
}

getEntries()

const loadMore = () => {
  if (currentPage.value < totalPage.value) {
    currentPage.value++
    getEntries()
  }
}

const selectEntry = (entry) => {
  selectedEntry.value = entry
  let element = document.getElementById("map");
  element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
}

watch(key, () => {
  currentPage.value = 1
  entries.value = []
  getEntries()
})


watch(map, () => {
  bread.value = [
    {
      text: t('satellite.title'),
      link: { name: 'satellite' }
    },
    {
      text: map.value.name
    }
  ]
})
</script>

<template>
  <div>
    <Breadcrumbs :items="bread"/>

    <Loader
      v-if="map === null"
      class="mt-4"
    />
    <VRow v-else class="match-height mb-6">

      <VCol
        md="6"
        lg="8"
        cols="12"
      >
        <VCard
          :title="map.name"
        >
          <VCardText>
            <VRow>
              <VCol
                cols="12"
                order="2"
                order-sm="1"
              >
                <VRow>
                  <VCol
                    v-if="map.area"
                    cols="12"
                    lg="6"
                    sm="3"
                    class="text-no-wrap"
                  >
                    <span class="me-2">{{ $t('maps.area') }}</span>

                    <VChip
                      label
                      color="primary"
                    >
                      <span v-html="map.area + ' ha'" />
                    </VChip>
                  </VCol>
                  <VCol
                    cols="12"
                    lg="6"
                    sm="3"
                    class="text-no-wrap"
                  >
                    <span class="me-2">{{ $t('layout.active') }}</span>

                    <VChip
                      label
                      :color="map.active ? 'primary' : 'warning'"
                    >
                      {{ map.active ? $t('layout.yes') : $t('layout.no') }}
                    </VChip>
                  </VCol>

                  <VCol
                    v-if="map.last_data"
                    cols="12"
                    lg="6"
                    sm="3"
                    class="text-no-wrap"
                  >
                    <span class="me-2">{{ $t('satellite.last_data') }}</span>

                    <VChip
                      label
                      :color="'primary'"
                    >
                      {{ moment.utc(map.last_data).local().format('Y-MM-DD') }}
                    </VChip>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    cols="12"
                    md="6"
                  >
                    <AppSelect
                      v-model="key"
                      :items="keys"
                      item-title="name"
                      item-value="key"
                      :label="$t('satellite.index')"
                      :placeholder="$t('satellite.select_index')"
                    />
                  </VCol>
                </VRow>
              </VCol>
            </VRow>
          </VCardText>
          <VCardText v-if="key">
            <h2>{{ $t('satellite.indexes.' + key ) }}</h2>
            <p>
              {{ $t('satellite.indexes.' + key + '_description') }}
            </p>
          </VCardText>
          <VCardActions
              class="notification-footer"
            >
              <VBtn @click="isInfoDialogVisible = true">
                {{ $t('satellite.more_info' ) }}
              </VBtn>
            </VCardActions>
        </VCard>
      </VCol>

      <VCol
        cols="12"
        lg="4"
        md="6"
      >
        <SoilCard :parcel="map" />
      </VCol>


      
    </VRow>
      
    <VCard id="map" class="mb-6">
      <Loader
        v-if="map === null"
        class="mt-4"
      />
      <VCardText v-else>
        <div
          style="height: 500px;"
        >
          <LMap
            :zoom="12"
            :center="map.center"
            :max-bounds="map.bounds"
            :use-global-leaflet="false"
            @ready="onMapReady"
          >
            <LFullScreen :map-ref="mapRef" />
            <LImageOverlay
              v-if="selectedEntry"
              :url="selectedEntry.image"
              :bounds="map.bounds"
            />
            <LTileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              layer-type="base"
              name="OpenStreetMap"
              :visible="false"
            />
            <LTileLayer
              :url="urlSattelite"
              layer-type="base"
              name="Mapbox Satellite"
              visible
            />
            <LTileLayer
              :url="urlOutdoors"
              layer-type="base"
              name="Mapbox Outdoors"
              :visible="false"
            />
            <LGeoJson
              v-if="map.geojson"
              :geojson="map.geojson"
              name="Geojson"
              layer-type="overlay"
            />
            <LControlLayers
              :collapsed="false"
              position="topright"
            />
          </LMap>
        </div>
      </VCardText>
    </VCard>

    <div v-if="entries.length > 0">
      <VCard
        v-if="key !== 'truecolor' && key !== 'falsecolor'"
        class="mb-6"
      >
          <VCardText>
            <ChartJsLineChart :entries="entries" :title="key"/>
          </VCardText>
      </VCard>

      <VCard
        v-for="item in entries"
        :key="item.uuid"
        class="mb-2"
        :color="selectedEntry.uuid === item.uuid ? 'light-secondary' : ''"
        @click="selectEntry(item)"
      >
        <div class="d-flex flex-column-reverse flex-md-row">
          <div>
            <VCardItem>
              <VCardTitle>
                  {{ moment.utc(item.sampled_at).local().format('Y-MM-DD') }}
                  <VChip
                    label
                    color="primary"
                  >
                    {{ item.type }}
                  </VChip>
              </VCardTitle>
            </VCardItem>

            <VCardText
              v-if="key !== 'truecolor' && key !== 'falsecolor'"
            >
              <span class="me-2">{{ $t('satellite.min') }}</span>

              <VChip
                label
                color="secondary"
                class="mr-4"
              >
                {{ Math.round(item.min * 100)/100 }}
              </VChip>
              <span class="me-2">{{ $t('satellite.max') }}</span>

              <VChip
                label
                color="secondary"
                class="mr-4"
              >
                {{ Math.round(item.max * 100)/100 }}
              </VChip>
              <span class="me-2">{{ $t('satellite.average') }}</span>

              <VChip
                label
                color="light-primary"
              >
                {{ Math.round(item.mean * 100)/100 }}
              </VChip>
            </VCardText>

            <VCardActions>
              <VBtn @click="selectEntry(item)">{{ $t('layout.view') }}</VBtn>
            </VCardActions>
          </div>

          <div class="ma-auto">
            <VImg
              :width="100"
              :src="item.image"
              class="rounded"
            />
          </div>
        </div>
      </VCard>

      <VRow v-if="currentPage < totalPage" class="mt-4 d-flex flex-column align-center justify-center">
        <VBtn color="primary" @click="loadMore()">
          {{ $t('layout.load_more') }}
        </VBtn>
      </VRow>
    </div>
    <VAlert
      v-else
      color="light-warning"
      class="text-warning"
    >
      <span class="text-lg font-weight-medium">{{ $t('satellite.no_entries') }}</span>
    </VAlert>
    <VDialog
      v-model="isInfoDialogVisible"
      width="500"
    >
      <!-- Dialog close btn -->
      <DialogCloseBtn @click="isDialogVisible = !isDialogVisible" />

      <!-- Dialog Content -->
      <VCard title="Privacy Policy">
        <VCardText>
          {{ $t('satellite.disclaimer' )}}
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>
