<script setup>
import Footer from '@/views/front-pages/front-page-footer.vue'
import Navbar from '@/views/front-pages/front-page-navbar.vue'
import { useTitle } from '@vueuse/core'
import axios from '@/helpers/axios'
import moment from "moment";
import Breadcrumbs from '@/views/general/Breadcrumbs.vue'
import { useI18n } from 'vue-i18n'

definePage({ meta: { layout: 'blank', public: true } })

const observationData = ref(null)

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const uuid = route.params.uuid


const bread = ref([])

const getData = () => {
  axios.get('/landing/observation/' + uuid).then(response => {
    observationData.value = response.data
    useTitle(observationData.value.title + ' - Kropie')
    track(observationData.value.uuid)
  }).catch(e => {
    console.error(e)
    router.replace({ name: 'blog' })
  })
}

const track = (slug) => {
  axios.get('/landing/observation/' + uuid + '/track')
}

getData()



watchEffect(() => {
  bread.value = [
    {
      text: t('observations.title'),
      //link: { name: 'admin-logs' }
    },
    {
      text: observationData.value ? observationData.value.title : '...'
    }
  ]
})
</script>

<template>
  <div class="bg-surface">
    <!-- 👉 Navbar  -->
    <Navbar />

    <!-- 👉 Content -->
    <VContainer v-if="observationData"  class="mb-16">
      <br>
      <VRow class="mt-16">

        <VCol cols="12">
          <Breadcrumbs :items="bread"/>

          <div>
            <h3 class="text-h3 mb-2">
              {{ observationData?.title }}
            </h3>

            <div class="text-sm" v-if="observationData.occured_at">
              {{ moment.utc(observationData.occured_at).local().format("Y-MM-DD HH:mm") }}
            </div>

            <div>
              <h4 class="text-h4 mt-2">
                {{ observationData.country.emoji }} {{ observationData.country.name }} - {{ observationData.state.name }}
              </h4>
            </div>
          </div>

          <VDivider class="my-8" />

          <!-- eslint-disable vue/no-v-html -->
          
          



        </VCol>

      </VRow>
    </VContainer>

    <!-- 👉 Footer  -->
    <Footer class="mt-15" />
  </div>
</template>

<style lang="scss" scoped>
.card-list {
  --v-card-list-gap: 1rem;
}
</style>

<style lang="scss">
.v-breadcrumbs{
  .v-breadcrumbs-item{
    padding: 0 !important;
  }
}

.mb-8 img {
  max-width: 100%; /* Ensure images don't exceed container width */
  height: auto; /* Maintain aspect ratio */
}
</style>