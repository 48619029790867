<script setup>
import { useI18n } from 'vue-i18n'
import moment from "moment";
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'default',
    role: 'admin'
  },
})

const { t } = useI18n()

const searchQuery = ref('')
const rowPerPage = ref(10)
const currentPage = ref(1)
const totalPage = ref(1)
const totalItems = ref(0)
const items = ref([])

const getUsers = () => {
  axios.get('/admin/users', {
    params: {
      q: searchQuery.value,
      perPage: rowPerPage.value,
      currentPage: currentPage.value,
    },
  }).then(response => {
    items.value = response.data.data
    totalPage.value = response.data.meta.last_page
    totalItems.value = response.data.meta.total
  }).catch(e => {
    console.error(e)
  })
}

watchEffect(() => {
  getUsers()
})

watchEffect(() => {
  if (currentPage.value > totalPage.value)
    currentPage.value = totalPage.value
})


// 👉 Computing pagination data
const paginationData = computed(() => {
  const firstIndex = items.value.length ? (currentPage.value - 1) * rowPerPage.value + 1 : 0
  const lastIndex = items.value.length + (currentPage.value - 1) * rowPerPage.value
  
  return t('tables.showing', { firstIndex, lastIndex, totalItems: totalItems.value })
})

const resolveStatusColor = stat => {
  switch (stat) {
  case 'admin':
    return 'error'
  case 'tester':
    return 'warning'
  case 'user':
    return 'primary'
  }
}

const resolveStatusText = stat => {
  switch (stat) {
  case 'admin':
    return t('users.roles.admin')
  case 'tester':
    return t('users.roles.tester')
  case 'marketing':
    return t('users.roles.marketing')
  case 'user':
    return t('users.roles.user')
  default:
    return t('users.roles.user')
  }
}
</script>

<template>
  <div>
    <VCard
      v-if="items"
      id="items-list"
      :title="$t('users.title')"
    >
      <VCardText class="d-flex align-center flex-wrap gap-4">
        <!-- 👉 Rows per page -->
        <div
          class="d-flex align-center"
          style="width: 150px;"
        >
          <span class="text-no-wrap me-3">{{ $t('tables.show') }}</span>
          <VSelect
            v-model="rowPerPage"
            density="compact"
            :items="[10, 20, 30, 50]"
          />
        </div>

        <VSpacer />

        <div class="d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div class="items-list-filter">
            <VTextField
              v-model="searchQuery"
              :placeholder="$t('users.search_users')"
              density="compact"
            />
          </div>
        </div>
      </VCardText>

      <VDivider />

      <!-- SECTION Table -->
      <VTable class="text-no-wrap items-list-table">
        <!-- 👉 Table head -->
        <thead class="text-uppercase">
          <tr>
            <th scope="col">
            </th>

            <th scope="col">
              {{ $t('users.name') }}
            </th>

            <th scope="col">
              {{ $t('users.organization') }}
            </th>

            <th scope="col">
              {{ $t('users.phone') }}
            </th>

            <th scope="col">
              {{ $t('users.credits') }}
            </th>

            <th scope="col">
              {{ $t('users.last_seen') }}
            </th>

            <th scope="col">
              {{ $t('users.type') }}
            </th>

            <th scope="col" />
          </tr>
        </thead>

        <!-- 👉 Table Body -->
        <tbody>
          <tr
            v-for="user in items"
            :key="user.uuid"
            style="height: 3.75rem;"
          >
            <td>
              <VAvatar :image="user.avatar_thumbnail" />
            </td>

            <!-- 👉 Name -->
            <td>
              <RouterLink :to="{ name: 'admin-users-uuid', params: { uuid: user.uuid } }">
                {{ user.name }}
              </RouterLink>
            </td>

            <!-- 👉 Org -->
            <td>
              {{ user.organization }}
            </td>

            <!-- 👉 Phone -->
            <td>
              {{ user.phone }}
            </td>

            <!-- 👉 Credits -->
            <td>
              {{ user.credits }}
            </td>

            <!-- 👉 Last Seen -->
            <td>
              {{ user.last_seen_at ? moment.utc(user.last_seen_at).local().format("Y-MM-DD HH:mm") : '' }}
            </td>

            <!-- 👉 Processed -->
            <td>
              <VChip
                :color="resolveStatusColor(user.roles[0])"
                size="small"
                class="text-capitalize"
              >
                {{ resolveStatusText(user.roles[0]) }}
              </VChip>
            </td>

            <!-- 👉 Actions -->
            <td style="width: 8rem;">
              <VBtn
                icon
                variant="text"
                color="default"
                size="x-small"
                :to="{ name: 'admin-users-uuid', params: { uuid: user.uuid } }"
              >
                <VIcon
                  :size="22"
                  icon="tabler-eye"
                />
              </VBtn>
            </td>
          </tr>
        </tbody>

        <!-- 👉 table footer  -->
        <tfoot v-show="!items.length">
          <tr>
            <td
              colspan="8"
              class="text-center text-body-1"
            >
              {{ $t('users.no_users') }}
            </td>
          </tr>
        </tfoot>
      </VTable>
      <!-- !SECTION -->

      <VDivider />

      <!-- SECTION Pagination -->
      <VCardText class="d-flex align-center flex-wrap gap-4 py-3">
        <!-- 👉 Pagination meta -->
        <span class="text-sm text-disabled">
          {{ paginationData }}
        </span>

        <VSpacer />

        <!-- 👉 Pagination -->
        <VPagination
          v-model="currentPage"
          size="small"
          :total-visible="5"
          :length="totalPage"
        />
      </VCardText>
      <!-- !SECTION -->
    </VCard>
  </div>
</template>

<style lang="scss">
#items-list {
  .items-list-actions {
    inline-size: 8rem;
  }

  .items-list-filter {
    inline-size: 12rem;
  }
}
</style>
